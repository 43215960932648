/* eslint-disable arrow-body-style */
const settingsHome = () => import('./views/settings-home.vue');

export const settingsRoutes = [
  {
    path: '/settings',
    component: settingsHome,
    name: 'Settings',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
