// eslint-disable-next-line arrow-body-style
const homeView = () => import('./views/home-view.vue');

export const homeRoutes = [
  {
    path: '/',
    component: homeView,
    name: 'Home',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
