/* eslint-disable arrow-body-style */
const supportHome = () => import('./views/support-home.vue');

export const supportRoutes = [
  {
    path: '/support',
    component: supportHome,
    name: 'Support',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
