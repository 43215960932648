// libs
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { router } from '@/router';
import { useAuthStore } from '@/modules/auth/auth-store';
import { sleep } from '@/utils/sleep';

declare module 'axios' {
  export interface AxiosRequestConfig {
    retry?: number;
  }
}

const onRequest = (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  return Promise.resolve(config);
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const { response, config } = error;
  if (config) {
    if (
      (response?.status === 429 || response?.status === 503) && // on timeout or too many calls
      (config.retry === undefined || config.retry < 4) // retry maximum 4 times
    ) {
      config.retry = config.retry ? config.retry + 1 : 1;
      await sleep(2000 * config.retry);
      return axios(config);
    }

    if (response?.status === 403 && response.data) {
      const data = response.data as { message?: string };
      // if the user has not verified their account, push them to /verify
      if (
        'message' in data &&
        data.message === 'You must verify your account to access the service' &&
        router.currentRoute.value.name !== 'verify'
      ) {
        await router.push({ name: 'verify' });
        throw error;
      }
    }

    if (response?.status === 401) {
      const authStore = useAuthStore();

      // in case of the refresh failing
      if (config.url === '/auth/refresh') {
        authStore.deleteAuth();
        await router.push({
          name: 'Login',
          query: {
            error: 'You got disconnected due to inactivity',
          },
        });
        throw error;
      }

      await authStore.refreshToken();
      // if the refresh is a success, retry the call
      // We must recreate the instance, to pick up the cookie update
      const instance = axios.create({});
      return instance(config);
    }
  }

  throw error;
};

/**
 * Set interceptors to axios to catch 401 auth errors
 *  and refresh and 403 need to verify beta access error
 * @param axiosInstance an instance of axios to apply the interceptors to
 * @returns axios instance
 */
export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
