/**
 * Censor an url from any sensitive parameters (this MUST be used for analytics)
 * @param url the url to censor
 * @returns an url safe for analytics
 */
export function censorUrl(url: string) {
  // Replace the q, query state and code parameters from url https://regex101.com/r/Rlmmhz/1
  const queryRegex = /(?<=[&?]query=|q=|code=|state=)[^\n\r&]*/gm;
  return url.replaceAll(queryRegex, '[REDACTED]');
}

/**
 * censor any occurrences of an url inside an object
 * @param object the object to censor
 * @returns a cleaned object
 */
export function recursiveCensorUrlFromObject(object: {
  [key: string]: unknown;
}) {
  const censoredObject = object;
  const keys = Object.keys(object);
  for (const key of keys) {
    const value = object[key];

    // if this is a string, censor it
    if (typeof value === 'string') {
      const censored = censorUrl(value);
      // skip any string that has not been modified
      if (censored !== value) {
        censoredObject[key] = censored;
      }
    }

    // if this is a list
    if (Array.isArray(value)) {
      // recursively execute the function on each elements
      censoredObject[key] = value.map(
        (arrayElement: { [key: string]: unknown }) => {
          return recursiveCensorUrlFromObject(arrayElement);
        },
      );
    }

    // if this is a nested object
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      // @ts-expect-error this is a nested object
      censoredObject[key] = recursiveCensorUrlFromObject(value);
    }
  }
  return censoredObject;
}
