/* eslint-disable arrow-body-style */
const searchView = () => import('./views/search-view.vue');

export const searchRoutes = [
  {
    path: '/search',
    component: searchView,
    name: 'Search',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
