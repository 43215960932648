/**
 * Asynchronously wait for a number of milliseconds.
 * @param ms The number of milliseconds to wait
 * @returns void
 */
export function sleep(ms: number) {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-promise-executor-return
    return setTimeout(resolve, ms);
  });
}
