// router
import { createRouter, createWebHistory } from 'vue-router';

// routes
import { authRoutes } from '@/modules/auth/auth-router';
import { homeRoutes } from '@/modules/home/home-router';
import { onboardingRoutes } from '@/modules/onboarding/onboarding-router';
import { connectRoutes } from '@/modules/connect/connect-router';
import { supportRoutes } from '@/modules/support/support-router';
import { searchRoutes } from '@/modules/search/search-router';
import { appsRoutes } from '@/modules/apps/apps-router';
import { settingsRoutes } from '@/modules/settings/setting-router';

// store
import { useAuthStore } from '@/modules/auth/auth-store';
import { useUserStore } from '@/modules/user/user-store';

const routes = [
  ...authRoutes,
  ...homeRoutes,
  ...onboardingRoutes,
  ...connectRoutes,
  ...supportRoutes,
  ...appsRoutes,
  ...searchRoutes,
  ...settingsRoutes,
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from) => {
  const authStore = useAuthStore();
  const isAuth = authStore.verifyAuth();
  // if the route is protected and the user not auth, redirect to login
  if (to.meta.requireAuth && isAuth === false) {
    authStore.isLoading = false;
    return {
      name: 'Login',
    };
  }

  if (isAuth === true) {
    // prevent seeing auth page if the user is already auth
    if (to.name === 'Login' || to.name === 'Register') {
      authStore.isLoading = false;
      return {
        name: 'Home',
      };
    }

    // now verify get the user data to redirect them to the right page
    const userStore = useUserStore();
    await userStore.fetchUser();
    if (userStore.user) {
      // if the user is not verified, send them to verify
      if (userStore.user.verified !== true && to.name !== 'verify') {
        return { name: 'verify' };
      }

      // if the user has been verified
      if (userStore.user.verified === true) {
        // prevent verified users from accessing the verify page
        if (to.name === 'verify') {
          return { name: 'Home' };
        }

        // if the user has not done onboarding, redirect them here
        if (
          userStore.user.onboardVersion === undefined &&
          to.name !== 'onboard'
        ) {
          return { name: 'onboard' };
        }
        // finally, if the user has done onboarding, prevent them from accessing the page
        if (
          userStore.user.onboardVersion === '1.0.0' &&
          to.name === 'onboard'
        ) {
          return { name: 'Home' };
        }
      }
    }
  }

  // if none of those conditions fire, simply let the user carry on
  authStore.isLoading = false;
  return true;
});
