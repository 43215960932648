/* eslint-disable arrow-body-style */
const connectView = () => import('./views/connect-view-vendors.vue');
const ConnectViewVendorTool = () =>
  import('./views/connect-views-vendor-tool.vue');
const connectCallbackView = () => import('./views/connect-views-callback.vue');

export const connectRoutes = [
  {
    path: '/connect',
    component: connectView,
    name: 'Connect',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
  {
    path: '/connect/accounts/:vendorTool',
    component: ConnectViewVendorTool,
    name: 'VendorAccounts',
    props: true,
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
  {
    path: '/connect/callback/:vendor',
    component: connectCallbackView,
    name: 'ConnectCallback',
    props: true,
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
