<template>
  <div class="main-loading" v-if="authStore.isLoading === true">
    <Icon icon="svg-spinners:blocks-wave" class="loading-spinner" />
    <h1>Ansearch is starting</h1>
  </div>
  <AppLayout v-else>
    <router-view />
  </AppLayout>
</template>

<script setup lang="ts">
// icons
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/vue';
// libs
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import posthog from 'posthog-js';
// stores
import { useAuthStore } from './modules/auth/auth-store';
// utils
import { censorUrl } from './utils/utils-censor';

const router = useRouter();
const authStore = useAuthStore();

watch(
  // eslint-disable-next-line arrow-body-style
  () => router.currentRoute.value.path,
  () => {
    posthog.capture('$pageview', {
      // why using the path rather than the full path?
      // because while searching, the full path will update a lot, producing false pageViews
      $current_url: censorUrl(
        `${window.location.origin}${router.currentRoute.value.path}`,
      ),
    });
  },
);

/**
 * This event listener track when the user closes the tab, and send a pageLeave event
 */
window.addEventListener('beforeunload', (_event) => {
  posthog.capture('$pageleave', {
    $current_url: censorUrl(
      `${window.location.origin}${router.currentRoute.value.path}`,
    ),
  });
});

/**
 * This event listener track when the user is active on the page and when they are not.
 * On mobile, this is a better way to track when the user leaves
 */
window.addEventListener('visibilitychange', (_event) => {
  if (document.visibilityState === 'hidden') {
    posthog.capture('left_session', {
      $current_url: censorUrl(
        `${window.location.origin}${router.currentRoute.value.path}`,
      ),
    });
  }
  if (document.visibilityState === 'visible') {
    posthog.capture('resume_session', {
      $current_url: censorUrl(
        `${window.location.origin}${router.currentRoute.value.path}`,
      ),
    });
  }
});
</script>

<style scoped>
.main-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.loading-spinner {
  font-size: 6rem;
}
</style>
