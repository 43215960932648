/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/check-param-names */
import axios from 'axios';
import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';

// models
import { VendorTool } from '@/modules/connect/models/connect-models-vendors';
import { GetUserResponse, User } from './models/user-models';

interface userStoreState {
  isLoading: boolean;
  user?: User;
}

export const useUserStore = defineStore('user', {
  // eslint-disable-next-line arrow-body-style
  state: (): userStoreState => ({
    isLoading: false,
  }),

  getters: {
    /**
     * Get a list with only the connected tools from a vendor tool (ex: list all the connected accounts of Google/Drive)
     * @param VendorTool the vendor tool string (usually vendor/tool)
     * @returns a list
     */
    filteredByVendorTool: (state) => {
      return ({ vendorToolString }: { vendorToolString: VendorTool }) => {
        return state.user?.vendorToolsMetadata.filter((tool) => {
          return tool.vendorTool.includes(vendorToolString);
        });
      };
    },

    /**
     * This filter function will return any other tool related to the ID given.
     * This is useful to have all the Google tools connected to an account for example
     * @param toolId the tool ID
     * @returns the list of tools that have the same toolID
     */
    getRelatedTools: (state) => {
      return ({ toolId }: { toolId: string }) => {
        if (!state.user) return [];
        return state.user.vendorToolsMetadata.filter((tool) => {
          return tool.toolId === toolId;
        });
      };
    },

    /**
     * Verify if a tool has never been sync
     * @returns true if there is a hard lock
     */
    hasNeverSync(): boolean {
      // @ts-expect-error a use case without a user cannot happen with the current code
      return this.user.vendorToolsMetadata.some((tool) => {
        return tool.lastSync === undefined;
      });
    },
  },

  actions: {
    /**
     * Fetch the user data from the backend.
     * This action will do nothing if a user is already in memory
     * @param force force fetching a new user
     */
    async fetchUser(force = false) {
      if (force === true || this.user === undefined) {
        this.isLoading = true;
        try {
          const { data }: { data: GetUserResponse } =
            await axios.get('/user/me');
          // set the user
          this.user = {
            _id: data._id,
            createdAt: data.createdAt,
            email: data.email,
            verified: data.verified,
            authVendor: data.authVendor,
            onboardVersion: data.onboardVersion,
            username: data.username,
            vendorTools: new Set(data.vendorTools),
            vendorToolsMetadata: data.vendorToolsMetadata,
          };
          this.isLoading = false;
        } catch {
          this.isLoading = false;
          // console.log(error);
          // Why no Sentry catch here? because this is called all the time, including when the user is not logged in
          // aka, we would have a lot of false positive here
          // We could put back sentry when / if we can filter errors from a user who is actually logged in
        }

        // and identify the user
        if (this.user !== undefined && this.user._id && this.user.email) {
          posthog.identify(this.user._id);
          Sentry.setUser({
            id: this.user._id,
            email: this.user.email,
          });
        }
      }
    },

    /**
     * Request an indexing for every connected tool in an account.
     * This function makes use of parallelism to spin multiple processing at once on the server
     */
    async indexAll() {
      // todo
      await axios.post('/index');
    },
  },
});
