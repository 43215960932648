import axios from 'axios';
import { defineStore } from 'pinia';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/vue';

interface AuthStoreState {
  isLoading: boolean;
  error?: string;
  isAuth: boolean;
}

export const useAuthStore = defineStore('auth', {
  // eslint-disable-next-line arrow-body-style
  state: (): AuthStoreState => ({
    /**
     * This variable is initialized to `true`, so the user will face the loading screen until they are authenticated.
     * This variable has a big impact on the user experience: it is tied to the main loading screen.
     *
     * **Note:** As long as it stays `true`, the user CANNOT see the application, since there will be a big loading screen.
     */
    isLoading: true,
    isAuth: false,
  }),

  actions: {
    /**
     * Verify if a user is authenticated.
     * @returns boolean
     */
    verifyAuth() {
      // if the state in memory is false, verify the storage
      if (this.isAuth === false) {
        const isAuthStorage = localStorage.getItem('isAuth');
        if (isAuthStorage === null) {
          return false;
        }
        // else set up the variable in memory for faster access in the future
        this.isAuth = true;
      }
      return true;
    },

    /**
     * set and save the user status as authenticated (true)
     */
    saveAuth() {
      localStorage.setItem('isAuth', 'true');
      this.isAuth = true;
    },

    /**
     * set and save the user status as not authenticated (false).
     * **Note**: this does not log the user out!
     */
    deleteAuth() {
      localStorage.removeItem('isAuth');
      this.isAuth = false;
      posthog.reset();
      Sentry.setUser(null); // eslint-disable-line unicorn/no-null
    },

    /**
     * Log the user out
     */
    async logout() {
      await axios.post('/auth/logout');
      this.deleteAuth();
      await this.router.push({ name: 'Login' });
    },

    /**
     * This function should be only called by Axios interceptors
     * It will start the logic for refreshing the tokens.
     * Should the refresh token fail, you need to delete the auth and redirect the user to the login page
     */
    async refreshToken() {
      // no need to encapsulate in try catch here:
      // the axios interceptor is already preventing spam of this function and failures
      await axios.post('/auth/refresh');
    },
  },
});
