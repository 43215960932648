/* eslint-disable arrow-body-style */
const verify = () => import('./views/onboarding-views-verify.vue');
const onboard = () => import('./views/onboarding-views-form.vue');
const welcome = () => import('./views/onboarding-views-video.vue');

export const onboardingRoutes = [
  {
    path: '/onboard',
    component: onboard,
    name: 'onboard',
    meta: {
      requireAuth: true,
      layout: 'emptyLayout',
    },
  },
  {
    path: '/verify',
    component: verify,
    name: 'verify',
    meta: {
      requireAuth: true,
      layout: 'emptyLayout',
    },
  },
  {
    path: '/welcome',
    component: welcome,
    name: 'welcome',
    meta: {
      requireAuth: true,
      layout: 'emptyLayout',
    },
  },
];
