<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { markRaw, ref, watch, Ref } from 'vue';
import { useRoute } from 'vue-router';

import defaultLayout from './layout-default.vue';
import emptyLayout from './layout-empty.vue';

const route = useRoute();
const layout: Ref<unknown> = ref();

watch(
  // eslint-disable-next-line arrow-body-style
  () => route.meta,
  (meta) => {
    layout.value =
      meta.layout && meta.layout === 'emptyLayout'
        ? markRaw(emptyLayout)
        : markRaw(defaultLayout);
  },
);
</script>
