// this eslint rule is here because resolving the type of import is difficult, should someone know how to do this, please disable the rule override
/* eslint-disable @typescript-eslint/no-unsafe-argument */
// libs
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/vue';

// config
import { APP_VERSION } from '@/config/config-runtime';

// services
import { setupInterceptorsTo } from '@/services/services-axios-interceptor';
// layouts
import AppLayout from '@/layouts/layout-selector.vue';

import App from './App.vue';
import { router } from './router';
import './style.css';
import { censorUrl, recursiveCensorUrlFromObject } from './utils/utils-censor';

// analytics
const posthogKey = import.meta.env.VITE_PH_KEY as string;
posthog.init(posthogKey, {
  api_host:
    import.meta.env.MODE === 'production'
      ? 'https://watermelon.ansearch.net'
      : 'https://eu.posthog.com/', // this little hack helps prevent the third party cookie warning, while using the reverse proxy in prod
  secure_cookie: true,
  autocapture: false, // this cannot be turned on until we can censor URLs
  capture_pageleave: false, // This is handled in the App.vue due to URL censorship
  capture_pageview: false, // This is handled in the App.vue
  session_recording: {
    maskInputOptions: {
      password: true,
      email: true,
      search: true,
      url: true,
      textarea: true,
    },
    maskTextSelector: '#sensitive',
    maskCapturedNetworkRequestFn: (data) => {
      // Crude censor (we simply remove all the data rather than picking the exact sensitive fields)
      // it can be (carefully) improved to include those while censoring the fields within
      const redactedData = data;
      redactedData.requestBody = undefined;
      redactedData.requestHeaders = undefined;
      redactedData.responseBody = undefined;
      redactedData.responseHeaders = undefined;
      return redactedData;
    },
  },
});
// posthog.debug(true);

// setup interceptors for every axios call & base url
setupInterceptorsTo(axios);
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  import.meta.env.MODE === 'development'
    ? 'https://localhost:5173/api' // this strange tweak is needed to use the proxy, which in turns allows using http cookies with an https frontend. Otherwise chrome will block the cookies due to CORS
    : 'https://api.ansearch.net';

const app = createApp(App);
const pinia = createPinia();

console.log(`App version: ${APP_VERSION}`); // eslint-disable-line no-console

// enable Sentry
if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    app,
    release: `frontend@${APP_VERSION}`,
    dsn: 'https://bf4ecb523f059827e97851ca8b542a4c@o4506366411669504.ingest.sentry.io/4506366417305600',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://api.ansearch.net'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        mask: ['#sensitive'],
        beforeAddRecordingEvent: (event) => {
          // censor any occurrence of an URL with a query string or code/state
          // @ts-expect-error TS being fussy about objects
          const censoredEvent = recursiveCensorUrlFromObject(event) as unknown;
          return censoredEvent as Sentry.ReplayFrameEvent;
        },
      }),
      // we cannot use the posthog plugin yet, because it leaks urls
      // new posthog.SentryIntegration(posthog, 'ansearch', 4506366417305600),
    ],
    environment: import.meta.env.MODE,
    attachStacktrace: true,
    // Performance Monitoring
    tracesSampleRate: 1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, _hint) {
      // censor any occurrence of an URL with a query string or code/state
      // @ts-expect-error TS being fussy about objects
      const censoredEvent = recursiveCensorUrlFromObject(event);
      posthog.capture('$exception', {
        $current_url: censorUrl(window.location.href),
      });
      return censoredEvent;
    },
    beforeBreadcrumb(breadcrumb, _hint) {
      // censor any occurrence of an URL with a query string or code/state
      // @ts-expect-error TS being fussy about objects
      const censoredEvent = recursiveCensorUrlFromObject(breadcrumb);
      return censoredEvent;
    },
    trackComponents: ['Header', 'Navigation', 'Footer'],
    hooks: ['create', 'mount'],
  });
}
app.use(pinia);
pinia.use(({ store }) => {
  // eslint-disable-next-line no-param-reassign
  store.router = markRaw(router);
});
app.use(router);
app.component('AppLayout', AppLayout);
app.mount('#app');
