/* eslint-disable arrow-body-style */
const appView = () => import('./views/apps-view.vue');

export const appsRoutes = [
  {
    path: '/apps',
    component: appView,
    name: 'Apps',
    meta: {
      requireAuth: true,
      layout: 'defaultLayout',
    },
  },
];
